import React from "react"
import { graphql } from "gatsby"

import Button from "../../components/Button"

import { HeroModel } from "../../models/blocks"

import { useAppModalContext } from "../../context/ModalContext"

import * as utils from "../../utils"
import VideoFile from "../../components/VideoFile"
import Text from "../../components/UI/Text"

export interface HeroProps extends HeroModel {}

const defaultProps = {}

const Hero: React.FC<HeroProps> = props => {

  const hasVideo = props.image?.file?.url?.indexOf("mp4") !== -1

  const aosClass = `aos-${Math.floor(Math.random() * 100000)}`

  return (
    <div className={`hero ${aosClass}`}>
      <div className="grid-container">
        <div className="grid-x grid-margin-x align-middle">
          <div className="cell large-shrink hero__imageWrapper">
            <div
              data-aos="fade-right"
              data-aos-delay={utils.aosBaseDelay}
              data-aos-anchor={`.${aosClass}`}
            >
              {props.image && (
                <>
                  {hasVideo && <VideoFile {...props.image} controls />}
                  {!hasVideo && (
                    <img
                      src={props.image?.file.url}
                      alt={props.image?.description || "hero image"}
                      className="hero__image"
                      data-aos="fade-right"
                    />
                  )}
                </>
              )}
            </div>
          </div>

          <div className="cell large-auto">
            <div className="hero__content">
              <Text content={props.content} options="body" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Hero.defaultProps = defaultProps

export default Hero

export const query = graphql`
  fragment HeroFields on ContentfulBlockHero {
    contentful_id
    id
    image {
      ...MediaImageFields
      gatsbyImageData(height: 410, placeholder: BLURRED, quality: 95)
    }
    content {
      raw
      references {
        ... on ContentfulButton {
          ...ButtonFields
        }
      }
    }
    internal {
      type
    }
  }
`
