import React from "react"
import { graphql } from "gatsby"

import get from "lodash/get"
import { Helmet } from "react-helmet"

import Hero from "../blocks/Hero/Hero"
import Text from "../components/UI/Text"

import { HomepageModel } from "../models/pages"

const defaultProps = {
  metaTitle: undefined,
  metaDescription: undefined,
  metaImage: undefined,
}

export const HomePage: React.FC<HomepageModel> = props => {
  // page data
  const page = get(props, "data.page")
  const hero = page.hero

  return (
    <>
      <Helmet bodyAttributes={{ class: `homepage ${page.background || ""}` }} />

      {hero && <Hero {...hero} />}

      <div className="grid-container">
        <Text content={page.body} options="body" />
      </div>
    </>
  )
}

HomePage.defaultProps = defaultProps

export default HomePage

/******************************************************
	Data
******************************************************/
export const query = graphql`
  query HomePageByID($pageId: String!) {
    page: contentfulPageHomepage(id: { eq: $pageId }) {
      contentful_id
      id
      slug
      hero {
        ...HeroFields
      }
      body {
        raw
        references {
          ...RichTextBodyFields
        }
      }
      metaTitle
      metaDescription {
        metaDescription
      }
      metaImage {
        ...MediaImageFields
        gatsbyImageData(width: 820, placeholder: BLURRED, quality: 95)
      }
      internal {
        type
      }
    }
  }
`
